<template>
  <div class="appointment-summary__boleto">
    <div class="appointment-summary__boleto-line">
      <div class="appointment-summary__boleto-icon">
        <a
          :href="paymentMethodUrl"
          target="_blank"
          class="appointment-summary__boleto-link"
        >
          <icon
            class="cursor-pointer"
            name="boletoPay"
            color="white"
          />
        </a>
      </div>

      <a
        :href="paymentMethodUrl"
        target="_blank"
        class="appointment-summary__boleto-text"
      >
        <span class="appointment-summary__boleto-text--accent">
          baixe agora o boleto
        </span>
        e
        <strong>faça o pagamento ainda hoje</strong>
      </a>
    </div>

    <div class="appointment-summary__boleto-line">
      <p class="appointment-summary__boleto-text">
        O não pagamento do boleto implicará no cancelamento
        automático deste agendamento.
      </p>
    </div>
  </div>
</template>

<script>
import Icon from '@/components/ui/Icon.vue';

export default {
  name: 'BoletoDisplay',

  props: {
    paymentMethodUrl: {
      type: String,
      required: false,
      default: () => '',
    },
  },

  components: {
    Icon,
  },
};
</script>
