<template>
  <section class="appointment-summary">
    <div class="appointment-summary__msg">
      <img
        :src="require(`@/assets/img/icons/${partner.theme}-ico-agendamento-sucesso.png`)"
        class="appointment-summary__msg-icon"
      >

      <h3 class="appointment-summary__msg-title">
        Consulta agendada com sucesso!
      </h3>
    </div>

    <transition name="fade">
      <pix-qr-code
        v-if="!loading && methodName === 'pix'"
        :payment-method-url="paymentMethodUrl"
        :start-date="creationDate"
        :agenda-id="agendaUuid"
        class="my-8"
      />
    </transition>

    <p class="appointment-summary__text">
      Confira abaixo os detalhes:
    </p>

    <div class="appointment-summary__box">
      <div class="appointment-summary__list">
        <div class="appointment-summary__list-item">
          <h4 class="appointment-summary__list-title">
            Médico
          </h4>

          <p class="appointment-summary__list-text">
            Dr(a). {{ doctorName }}
          </p>
        </div>

        <div class="appointment-summary__list-item">
          <h4 class="appointment-summary__list-title">
            Especialidade
          </h4>

          <p class="appointment-summary__list-text">
            {{ specialtyName | toSpecialtyName }}
          </p>
        </div>

        <div class="appointment-summary__list-item">
          <h4 class="appointment-summary__list-title">
            Data e horário
          </h4>

          <p class="appointment-summary__list-text">
            {{ datetime }}
          </p>

          <p class="text-center appointment-date__title--fuse !mt-1 w-full">
            *Horário de Brasília
          </p>
        </div>

        <div
          v-if="isPresential"
          class="appointment-summary__list-item"
        >
          <h4 class="appointment-summary__list-title">
            Endereço
          </h4>

          <p class="appointment-summary__list-text">
            {{ address }}
          </p>
        </div>

        <div class="appointment-summary__list-item">
          <h4 class="appointment-summary__list-title">
            Método de pagamento
          </h4>

          <p class="appointment-summary__list-text">
            {{ method }} {{ isPrepaid ? partner.name : '' }}
          </p>
        </div>

        <div
          v-if="!isPrepaid"
          class="appointment-summary__list-item"
        >
          <h4 class="appointment-summary__list-title">
            Valor da consulta
          </h4>

          <p class="appointment-summary__list-text">
            {{ installment }}x de RS {{ price | toMoney(installment) }}
          </p>
        </div>
      </div>
    </div>

    <template v-if="!loading">
      <boleto-display
        v-if="methodName === 'boleto'"
        :payment-method-url="paymentMethodUrl"
      />
    </template>

    <div class="appointment-summary__call-to-action">
      <!-- Placeholder adicionar a agenda -->
      <ui-button
        v-if="false"
        label="Adicionar ao Google agenda"
        @click="openModalDialog({
          title: 'Título do modal',
          type: 'confirmation',
          text: `A expressão Lorem ipsum
            em design gráfico e editoração é
            um texto padrão em latim utilizado
            na produção gráfica para preencher
            os espaços de texto em publicações
            para testar e ajustar aspectos visuais
            antes de utilizar conteúdo real.`,
          cancelText: 'tchau',
          confirmText: 'oi',
        })"
      />
    </div>

    <div class="disclaimer">
      <h5 class="disclaimer__title">
        <img
          :src="require('@/assets/img/icons/ico-warning.svg')"
          class="disclaimer__icon"
        >

        Orientações importantes
      </h5>

      <p class="disclaimer__text">
        Em instantes, lhe enviaremos um e-mail com as informações do agendamento.
        No dia e horário marcados, você receberá um lembrete por telefone e um SMS
        com o link para a teleconsulta.
        <strong>
          Lembre-se que é necessário ter um computador com uma webcam (ou celular
          com câmera frontal) e acesso à internet.
        </strong>
      </p>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { format, parseISO } from 'date-fns';
import ptBr from 'date-fns/locale/pt-BR';
import getPaymentMethodUrl from '@/api/appointmentDetails/paymentMethodUrl';
import toSpecialtyName from '@/filters/specialtyName';
import toMoney from '@/filters/money';
import UiButton from '@/components/ui/Button.vue';
import BoletoDisplay from '@/components/appointmentScheduling/paymentMethods/boletoDisplay.vue';
import PixQrCode from '@/components/appointmentScheduling/paymentMethods/pixQRCode.vue';

export default {
  name: 'AppointmentSchedulingSummary',

  filters: {
    toSpecialtyName,
    toMoney,
  },

  components: {
    UiButton,
    BoletoDisplay,
    PixQrCode,
  },

  data() {
    return {
      agendaUuid: null,
      doctorName: null,
      specialtyName: null,
      datetime: null,
      installment: null,
      price: null,
      isPresential: false,
      address: null,
      method: null,
      methodName: null,
      paymentMethodUrl: null,
      isPrepaid: false,
      creationDate: null,
      loading: true,
    };
  },

  computed: {
    ...mapGetters('partner', ['partner']),
    ...mapGetters('scheduling', [
      'office',
      'doctor',
      'payment',
      'appointment',
    ]),
  },

  created() {
    if (document.domain === 'paciente.drconecta.com.br') {
      if (Object.prototype.hasOwnProperty.call(window, 'fbq')) {
        window.fbq('track', 'PatientFinishPayment');
      }

      if (Object.prototype.hasOwnProperty.call(window, 'gtag')) {
        window.gtag('event', 'conversion', {
          'send_to': 'AW-415408362/zYjICN-a0fYBEOrBisYB',
          'transaction_id': '',
        });
      }
    }

    if (this.payment.method.name === 'prepaid' || this.payment.method.name === 'voucher') {
      this.isPrepaid = true;
    }

    this.agendaUuid = this.appointment.uuid;
    this.doctorName = this.doctor.name;
    this.specialtyName = this.appointment.specialty.name;
    this.datetime = format(
      parseISO(`${this.appointment.date} ${this.appointment.time}`),
      'd \'de\' MMMM \'de\' yyyy \'às\' HH\':\'mm',
      { locale: ptBr },
    );
    this.installment = this.payment.installments;
    this.price = this.payment.price;
    this.method = this.payment.method.label;
    this.methodName = this.payment.method.name;
    this.isPresential = this.appointment.modality === 'presential';
    this.address = this.office.address || '';
    this.creationDate = this.appointment.creationDate;

    const loadFn = () => {
      if (this.payment.method.name === 'boleto' || this.payment.method.name === 'pix') {
        return new Promise(
          (resolve) => getPaymentMethodUrl(this.agendaUuid)
            .then((res) => resolve(res))
            .then(() => resolve()),
        );
      }

      return Promise.resolve();
    };

    this.toggleProgressBar();
    loadFn()
      .then((paymentMethodUrl) => {
        this.paymentMethodUrl = paymentMethodUrl;
      })
      .finally(() => {
        this.toggleProgressBar(false);
        this.resetProgressTrackerStep();
        [1, 2, 3].forEach((step) => this.completeProgressTrackerStep({ step, status: false }));
        this.resetScheduling();
        this.loading = false;
      });
  },

  methods: {
    ...mapActions('ui', [
      'resetProgressTrackerStep',
      'completeProgressTrackerStep',
      'toggleProgressBar',
    ]),
    ...mapActions('scheduling', ['resetScheduling']),
  },
};
</script>
